.favoris_page h1{
 text-align: center;
}


.empty_favorites{
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}