.login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid green;
    height: 100vh;
  }
  
  .login_slogan {
    width: 40%;
    height: 80%;
    border-radius: 8px;
    border: 1px solid #d93232;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 80px;
  }
  
  .form_login{
    width: 40%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center; 
    border-radius: 8px;
    margin-right: 80px;
 
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
  
  form {
    width: 80%;
    display: grid;
    gap: 20px; 
  }
  
  .input_pair {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; 
  }
  
  .input_pair_login{
    display: flex;
    flex-direction: column;
  }

  
  .title_login{
    margin: 0;
    color: #D93232;
    text-align: center;
  
  }

  .subtitle_login{
    margin: 0;
   
    text-align: center;
  
  }

  
  .login_question{
    text-align: center;
    
  }


  .login_question a{
    color: #D93232;
    
  }


  .show_icon{
    opacity: 1;
  }

  .hidden_icon{
    opacity: 0;
  }

  .btn_login{
    width: 150px;
    height: 30px;
    margin: 20px auto;
    background-color: #D93232;
    color: white;
    border-radius: 10px;
    border: none;
    font-weight: 500;
    cursor: pointer;
  }


  @media  screen and (max-width: 965px) {

    .input_pair{
        display: block;
    }
  }

  @media  screen and (max-width: 850px) {

    .login{
    width: 100%;  
    border: 1px solid green;
    height: auto;
    display: flex; 
    align-items: center;
    flex-direction: column;
    margin: auto; 
    }
    .input_pair{
      margin: auto;   
    }
    .form_login {
      width: 90%;
      height: 60vh;
       border: 1px solid purple;
        margin: 100px auto;
      }
      .title_login{
        width: 100%;
       text-align: center;
      }

      .login_slogan{
        width: 90%;
        margin: auto;
      }
  }




