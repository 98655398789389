.catalogue_accueil {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.cake_detail {

    border-radius: 10px;
    width: 340px;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 70px;
   position: relative;
   box-shadow: 0px 0px 4px 2px rgba(57, 57, 57, 0.5);
   box-sizing: border-box; 
   transition: transform 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
}

.cake_detail:hover {
  box-shadow: 0px 0px 6px 3px rgba(255, 188, 16, 0.7);
  transform: translateY(-10px); 
}

.cake_detail .cake_price{
width: 200px;
height: 72px;
color: #D93232;
background-color: white;
border-radius: 48px;
display: flex;
justify-content: center;
align-items: center;
font-size: 25px;
font-weight:bold;
text-align: center;
margin: -10px auto 0;
}

.image_item{
    width: 200px;
    height: 200px;
    margin-top: 10px;
  
}

.image_item img{
    width: 100%;
    height: 100%;
  
}

.mes_catalogues{
   background:  #FFBC10;
   width: 460px;
   height: 78px;
   display:'flex';
   justify-content: center;
   align-items:'center';
   text-align: center;
}

.mes_catalogues{
    background:  #FFBC10;
    display:flex;
    justify-content:center;
    width: 415px;
    height: 90px;
    /* border: 2px solid red; */
 margin: 88px auto 0;
    align-items: center;
   
 }

 .mes_catalogues a{
    /* border: 2px solid blue; */
      font-size: 28px; 

 }


 .icon_favori{
    /* border: 1px solid black; */
    position: absolute;
    top: 0;
    left: 85%;
 }




 @media screen and (max-width: 768px) {
    .mes_catalogues {
        width: 64%;
        padding: 15px;
        height: 100%;
        margin: 0;
    }
 
   
 }