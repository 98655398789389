.header {
    width: 100%;
    height: 132px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid #FFBC10;
    position: relative;
    padding: 0 30px;
    box-sizing: border-box;
}

.header .logo img {
    height: auto;
    max-height: 100%;
}

.nav-icons {
    display: none; /* Hidden on desktop */
    position: relative;

}

.nav-links {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 15px;
    }

    .nav-icons {
        display: flex;
        z-index: 3;
        position: absolute;
        left: 90%;
        top: 38%;
    }

    .nav-links {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 2;
        /* width: 100%; */
        background-color: white;
        border-top: 1px  solid #FFBC10;
        border-left: 1px  solid #FFBC10;
        border-bottom: 1px  solid #FFBC10;
        padding: 10px;
        box-sizing: border-box;
     
    }

  .nav-icons .icon.closeIcon{
    color: #D93232;
    font-size: 35px;
  }

  .nav-icons .menuIcon{
    font-size: 35px;
    margin: auto -14px;
  }
  
    .nav-links.show {
        display: block;
        left: 47%;
        opacity: 0.9;
        position: absolute;
        top: 30%;
        width: 53%;
        animation: mymove 0.5s ease-in;
    }



    @keyframes mymove {
        from { left: 70%;}
        to { left: 47%;}
      }



    .nav-links .icon {
        display: block;
    }
     
    

}
