/* .slogan_contain{
      height: 80vh; 
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      
}

.slogan{
  margin: 0;
  text-align: center;
}

.slogan_logo{
  width: 360px;
  height: 285px;
  margin: auto;
}
.slogan_logo img{
  width: 100%;
  height: 100%;
 
}


.slogan_text p{
  color: #D93232;
  font-weight: bolder;
  width: 470px;
  margin: auto;
  
}



@media  screen and (max-width: 850px) {

  .slogan_text p{
  font-size: 80%;
    
  }

}
 */







.slogan_contain {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  opacity: 0.1; 
  /* transition: opacity 3s ease;  */
  animation: example 2s ease-in-out forwards;
}

@keyframes example {
  from { opacity: 0.1 ;}
  to { opacity: 1 ;}
}


.slogan_contain.show {
  opacity: 1; 
}

.slogan {
  margin: 0;
  text-align: center;
}

.slogan_logo {
  width: 360px;
  margin: auto;
  height: 100%;
  overflow-y: hidden;
}

.slogan_logo img {
  width: 100%;
  height: 100%;
}

.slogan_text p {
  color: #D93232;
  font-weight: bolder;
  width: 470px;
  margin: auto;
  animation: slogan_text 3s ease-in-out forwards; 
}



@keyframes slogan_text {
  from { opacity: 0.1 ;}
  to { opacity: 1 ;}
}


@media screen and (max-width: 850px) {
  .slogan_text p {
      font-size: 80%;
  }
} 
