.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 100%;
    overflow: hidden;
}

.carousel-images {
    display: flex;
    transition: transform 0.5s ease;
    width: 100%;
}

.image-container {
    flex: 1;
    min-width: calc(100% / 3); /* 3 images à l'écran */
    box-sizing: border-box;
    padding: 60px;
    height: 600px;
}

.image-container img {
    width: 100%;
    height: 90%;
    display: block;
    border-radius: 10px;
}


.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #D93232;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
    font-weight: bold;
}

.prev-button {
    left: 0;
}

.next-button {
    right: 0;
}

@media screen and (max-width: 768px) {
    .image-container {
        max-width: 425px;
        display: flex;
        margin: -33px;
        justify-content: center;
        align-items: initial;
        height: 500px;
      
    }
    .image-container img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }
}
