*{
  font-family: "Roboto";
}


.App {
  /* text-align: center; */
  width: 100%;
  height: 100%;
  margin: 0;
}

html, body {
  overflow-x: hidden;
}
