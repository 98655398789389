.title_detail_commande{
  text-align: center;
}

.detail_commande{
  /* border: 2px solid green; */
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-between;
  background-color: #D9D9D9;
  align-items: center;
  height: 800px;
  padding: 0 35px;

}

.left_detail_commande{
  /* border: 2px solid blue; */
  width: 35%;
  display: flex;
  justify-content: center;
  background-color: white;
  height: 50%;
  padding: 25px;
  border-radius: 15px;

}

.right_detail_commande{
  /* border: 2px solid red; */
  width: 55%;
  height: 56%;
}


.right_detail_commande .name_price{
 /* border: 2px solid red; */
 display: flex;
 justify-content: space-between;

}

.right_detail_commande .name_price span{
  font-size: 40px;
  font-weight: bold;
  margin: 15px;
}

.right_detail_commande .name_price .name_cake{
  font-size: 27px;
 
}

.right_detail_commande .description_cake{
  /* border: 2px solid green; */
  margin-top: 15px;
  font-size: 25px;
  width: 100%;
  height: auto;
  max-height: 136px;
  overflow-y: auto;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.btn_add_remove{
  /* border: 2px solid purple; */
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn_add_remove .btn_qty{
  /* border: 2px solid yellow; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.btn_add_remove .btn_qty .qte_cake{
  margin: 0 10px; 
  font-size: 25px;
}

.btn_add_remove .btn_add_remove_item{
  background-color: #D93232;
  width: 39px;
  text-align: center;
  border-radius: 59px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 45px;
}

.btn_add_remove .btn_price{
  /* border: 2px solid brown; */
  width: 300px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #D93232;
}

.btn_add_remove .btn_price span{
color: white;
font-size: 35px;
font-weight: bold;
}


.commande {
  width: 100%;
  /* border: 2px solid red; */
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin-top: 49px;
}

.commande button{
  border-radius: 10px;
  height: 100%;
  width: 100%;
  font-size: 35px;
  color: white;
  background-color: #D93232;
  border: none;
  font-weight: bold;
}


.right_detail_commande .cancel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 70px auto;
  width: 100%;
 
}



.right_detail_commande .cancel .cancel-item{
  font-size: 30px;
  color: #D93232;
}










.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Empêche le scroll de la pop-up elle-même */
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  max-height: 90vh; /* Limite la hauteur de la pop-up à 80% de la hauteur de la fenêtre */
  overflow-y: auto; /* Permet de scroller le contenu à l'intérieur si nécessaire */
  position: relative;
  margin: 25px;
}

.popup-content section{
    display: flex;
    text-align: center;
}

.close {
    position: absolute;
    top: 10px;
    right: 6px;
    cursor: pointer;
    font-size: 24px;
    color: #D93232;
    /* border: 3px solid #D93232; */
    border-radius: 21px;
    width: 27px;
    border: 2px solid #D93232;
    border-radius: 15px;
}












/* .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
} */






@media (max-width: 768px) {
.detail_commande {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: auto;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
  }


.left_detail_commande{
  width: 70%;
  margin-right: 2px;
}

.left_detail_commande img{
  width: 100%;
}

.right_detail_commande {
  width: 85%;
  height: auto;
  margin: 20px auto;
}
  
  .right_detail_commande .name_price{
   
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .right_detail_commande .description_cake {
    font-size: 20px;
    margin-top: 30px;
    max-height: 136px;
    width: 100%;
    height: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  }

  .btn_add_remove {
    flex-direction: column;
    align-items: center;
  }

  .btn_add_remove .btn_qty {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 14px;
  }


  .right_detail_commande .cancel {
    margin: 45px auto;
  }



/*
  .left_detail_commande {
    padding: 20px;
    margin: auto 100px; 
  }

 
  .right_detail_commande .name_price span {
    font-size: 30px;
  }

  .right_detail_commande .description_cake {
    font-size: 20px;
    margin-top: 30px;
  }



  .btn_add_remove .btn_price {
    width: 100%;
    margin-top: 10px;
  }

  .btn_add_remove .btn_qty {
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .btn_add_remove .btn_add_remove_item {
    width: 100%;
    margin-bottom: 10px;
  }

  .commande {
    width: 100%;
    margin-top: 20px;
  }

  .right_detail_commande .cancel {
    width: 10%;
    margin: 20px auto;
  } */




}
