.copyright{
    
    background-color:  #FFBC10;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.copyright p{
    width: 90%;
 
}

.copyright p span{
  color:  red;
}

.copyright a {
 text-decoration: none;
}