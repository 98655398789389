.load{ 
height: 100vh;
display: flex;
justify-content: center;
align-items: center; 
}




.loader-container {
    position: relative;
    display: flex;
    height: 170px;
}

.photo_gateau {
    width: 100px; 
    height: auto;
    display: block;
    margin: 0 auto;
} 

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 8px solid rgba(0, 0, 0, 0.1); /* Light grey */
    border-top: 8px solid #FFBC10; /* Blue */
    border-radius: 50%;
    width: 200px; /* Taille du loader */
    height: 200px; /* Taille du loader */
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
}
