




.formular {
 
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formular form {
    
    margin: auto;
    background-color: #FFBC10;
    width: 90%;
}

.formular .form-container {
    /* border: 3px solid green; */
    padding: 20px; /* Ajusté pour plus d'espace sur les petits écrans */
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 80%;
    /* border: 2px solid green; */
}

.monpaiement{
    margin-top: 50px;
}

#date p{
    font-size: 15px;
    font-style: italic;
}

.form-group input[type=text],
/* input[type=number], */
textarea {
    width: 95%;
    height: 50px;
    overflow-x: hidden;
    overflow-y: scroll;
    border: none;
    margin: auto;
    font-size: 21px;
    text-align: center;
}

/* .form-group #form-item{
    font-size: 14px;
} */

.form-group textarea {
    height: auto;
  
}

#date {
    height: 45px;
}

label {
    color: black;
  margin: 4px auto;
  font-weight: bold;
}

.image_paiement {
    width: 100px;
}

.image_paiement img {
    width: 100%;
}

.form-group section {
    display: flex;
  
    flex-direction: column;
    gap: 10px;
}

.form-group section > div {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.form-group section > div input[type=radio] {
    margin-left: 10px;
}

#espece {
    margin-top: 0;
}

.btn {
    /* border: 2px solid purple; */
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
    width: 70%;
}

.btn button {
    width: 100%;
    height: 56px;
    border: none;
    font-weight: bold;
    cursor: pointer;
}

.btn button[type=button] {
    background-color: white;
    margin: 10px;
}

.btn button[type=button]:hover {
    opacity: 0.8;
}

.btn button[type=submit] {
    background-color: #D93232;
    color: white;
    margin: 10px;
}

.btn button[type=submit]:hover {
    opacity: 0.8;
}

.form-group textarea {
    resize: none;
    scrollbar-width: auto;
}


.validation{
  
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  

}

.validation .btn-submit , .btn-cancel {
   width: 100%;
   height: 56px; 
 
}

.validation .btn-cancel ,  .validation .btn-submit {
font-weight: bold;
border: none;
 }

 .validation .btn-submit {
    background-color: #D93232;
   color: white;

    }

    .validation .btn-cancel {
        background-color: white;
        color: black;
    }

/* Responsive Design */
@media (max-width: 768px) {


    .image-container {
        min-width: 100%;
        display: flex;
        margin: -33px;
        justify-content: center;
        align-items: initial;
    }

    .formular {
        height: auto; /* Ajustement de la hauteur pour les petits écrans */
        padding: 20px 0; /* Espacement supplémentaire pour une meilleure visualisation */
    }

    .formular .form-container {
        padding: 20px;
        gap: 10px;
    }

    .form-group {
        width: 90%; /* Augmentation de la largeur pour une meilleure utilisation de l'espace */
        margin: 10px auto;
    }
    .form-group textarea{
        font-size: 14px;
    }


   .monpaiement {
     margin-top: 50px;
   }



    .form-group .form-item::placeholder{
font-size: 14px;
    }


    .btn {
        flex-direction: column;
        gap: 10px;
        width: 90%; /* Ajusté pour correspondre à la largeur du formulaire */
    }

    .btn button {
        width: 100%;
    }

    .form-group section {
        flex-direction: column;
    }

    .form-group section > div {
        flex-direction: column; /* Alignement vertical des sections pour une meilleure lisibilité */
        gap: 10px; /* Ajout d'espace entre les éléments */
    }

    




}

/* Style de la pop-up de confirmation */
.confirmation-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1001;
}

.confirmation-popup p {
    margin-bottom: 20px;
}

.confirmation-popup button {
    margin-right: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirmation-popup button:first-child {
    background-color: #007bff;
    color: white;
}

.confirmation-popup button:last-child {
    background-color: #dc3545;
    color: white;
}
