/* 
#about {
    background-color: #f8f8f8; 
    padding: 50px 20px; 
    font-family: 'Georgia', serif; 
     
}

#about .container {
    max-width: 800px;
    margin: 0 auto; 
}

#about h2 {
    font-size: 36px; 
    text-align: center; 
    color: #D93232; 
    margin-bottom: 20px;
}

#about p {
    font-size: 18px; 
    line-height: 1.6; 
    margin-bottom: 20px; 
    text-align: justify; 
}


@media (max-width: 768px) {
    #about {
        padding: 70px 40px; 
    }
    
    #about h2 {
        font-size: 23px; 
    }
    
    #about p {
        font-size: 20px; 
    }
} */




#about {
    background-color: #f8f8f8; 
    padding: 60px 20px; 
    font-family: 'Georgia', serif; 
    color: #333; 
    line-height: 1.8;
}

#about .container {
    max-width: 900px;
    margin: 0 auto; 
    padding: 0 15px;
}

#about h2 {
    font-size: 36px; 
    text-align: center; 
    color: #d93232; 
    margin-bottom: 30px;
    letter-spacing: 1.5px;
}

#about p {
    font-size: 18px; 
    line-height: 1.8; 
    margin-bottom: 25px; 
    text-align: justify; 
    color: #000;  /* Une couleur plus douce pour le texte */
}

/*#about p strong {
    color: #d93232;  Utilisation de la couleur principale pour mettre en évidence 
}*/

@media (max-width: 768px) {
    #about {
        padding: 50px 20px; 
    }
    
    #about h2 {
        font-size: 28px; 
        margin-bottom: 20px;
    }
    
    #about p {
        font-size: 16px; 
    }
}

