h3{
    background-color: #D93232;
    color: white;
    border-radius: 10px;
    width: 159px;
    height: 36px;
    padding: 17px;
    text-align: center;
    align-items: center;
    display: flex;
}

.catalogue_title{
    /* border: 1px solid blue; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: auto;
}

.catalogue_title button{
    width: 53px;
    height: 53px;
}

.catalogue_title button img{
    width: 100%;
    height: 100%;
}

.catalogue_accueil a{
    text-decoration: none;
    color: black;
 
}

.catalogue_accueil .cake_name{
     color: black;
     text-align: center;
     width: 200px;
}

.search form{
    /* border: 1px solid green; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px auto;
    width: 85%;
}

.input_search{
    border: 1px solid #FFBC10;
    height: 55px;
    border-radius: 40px;
    width: 90%;
    margin: 12px auto;
    font-size: 25px;
}


.search form button{
    background: white;
    border: none;
}


