

.navlinks{
   
    width: 920px;
}

.navlinks .nav-items{ 
    display: flex;
    max-width: 100%;
}

.navlinks .nav-items .nav-item{
    list-style: none;
    font-size: 20px;
    margin: 0 20px;
}



.navlinks .nav-items .nav-item a{
text-decoration: none;
color: #FFBC10;

}



.navlinks .nav-items .nav-item a:hover{
    padding-bottom: 10px;
    border-bottom: 2px solid #FFBC10;
}


.nav-item {
    padding: 10px;
    cursor: pointer;
  }
  
.nav-item.active {
    border-bottom: 2px solid #FFBC10;
  }
  
  

@media screen and (max-width: 768px) {
  
    .navlinks {
        width: 100%;
    }

    .navlinks .nav-items {
        /* flex-wrap: wrap; */
        flex-direction: column;
        margin: 0;
    }

    .navlinks .nav-items .nav-item {
        margin: 1px 0;
        font-size: 15px;
    }


    

    .navlinks .nav-items .nav-item a:nth-child(1) {
        animation: moveLink ease-in;
        animation-duration: 0.5s;
        animation-delay: 0s;
    }
    
    .navlinks .nav-items .nav-item a:nth-child(2) {
        animation: moveLink ease-in;
        animation-duration: 0.5s;
        animation-delay: 2s;
    }
    
    .navlinks .nav-items .nav-item a:nth-child(3) {
        animation: moveLink ease-in;
        animation-duration: 0.5s;
        animation-delay: 5s; 
    }
    
    .navlinks .nav-items .nav-item a:nth-child(4) {
        animation: moveLink ease-in;
        animation-duration: 0.5s;
        animation-delay: 8s; 
    }
    
    .navlinks .nav-items .nav-item a:nth-child(5) {
        animation: moveLink ease-in;
        animation-duration: 0.5s;
        animation-delay: 11s;
    }
    
    .navlinks .nav-items .nav-item a:nth-child(6) {
        animation: moveLink ease-in;
        animation-duration: 0.5s;
        animation-delay: 13s; 
    }
    
    @keyframes moveLink {
        0%   { opacity: 0; }
        /* 25%  { opacity: 0.25; }
        50%  { opacity: 0.5; }
        75%  { opacity: 0.75; } */
        100% { opacity: 1; }
    }
    

} 




/* 
.navlinks {
    width: 920px;
    position: relative; 
}

.navlinks .nav-items {
    display: flex;
    max-width: 100%;
}

.navlinks .nav-items .nav-item {
    list-style: none;
    font-size: 20px;
    margin: 0 20px;
    padding: 10px;
    cursor: pointer;
}

.navlinks .nav-items .nav-item a {
    text-decoration: none;
    color: #FFBC10;
}

.navlinks .nav-items .nav-item a:hover {
    padding-bottom: 10px;
    border-bottom: 2px solid #FFBC10;
}

.nav-item.active {
    border-bottom: 2px solid #FFBC10;
}

/* 
.burger-menu {
    display: none;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 20px;
    color: #FFBC10;
    z-index: 1000;  
}

.close-btn {
    font-size: 24px;
    color: #FFBC10;
}

.burger-btn {
    font-size: 24px;
    color: #FFBC10;
}

@media only screen and (max-width: 768px) {
    .navlinks {
        width: 100%;
    }

    .burger-menu {
        display: block; 
    }

    .nav-items {
        display: none; 
        flex-direction: column;
        position: absolute;
        top: 50px; 
        right: 0;
        background-color: #333; /
        width: 100%;
        padding: 20px;
        z-index: 999; 
    }

    .nav-items.show {
        display: flex; 
    }

    .nav-items .nav-item {
        margin: 10px 0;
        text-align: right;
    }
} */
